<template>
	<div class="page">
		<div class="title">
			<div class="titleL">宠物基本信息</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<div class="tit">
			<div class="titL">
				<span class="titText">宠物资料</span>
				<img class="editImg" v-if="$buttonAuthority('huiyuan-huiyuanList-see-editPet')" src="../../../assets/img/shouyin/edit.png" @click="dialogEditPet_state=true"/>
			</div> 
			<div class="titR">
			</div>
		</div>
		<!-- 宠物资料 -->
		<div class="msgBox">
			<el-row>
				<el-col class="msgItem" :span="6">
					昵称：{{petDetail.nickName}}
				</el-col>
				<el-col class="msgItem" :span="6">
					种类：{{petDetail.applyType.title}}
				</el-col>
				<el-col class="msgItem" :span="6">
					品种：{{petDetail.categoryInfo.name}}
				</el-col>
				<el-col class="msgItem" :span="6">
					出生日期：{{$public.FTime(petDetail.bronDate,"YYYY-MM-DD")}}
				</el-col>
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					宠物年龄：
					<span v-if="$public.FAge(petDetail.bronDate)[0]>0">
						{{$public.FAge(petDetail.bronDate)[0]}}岁
					</span>
					<span v-if="$public.FAge(petDetail.bronDate)[1]>0">
						{{$public.FAge(petDetail.bronDate)[1]}}月
					</span>
				</el-col>
				<el-col class="msgItem" :span="6">
					芯片编号：{{petDetail.epromNo}}
				</el-col>
				<el-col class="msgItem" :span="6">
					犬证编号：{{petDetail.petCertificateNo}}
				</el-col>
				<el-col class="msgItem" :span="6">
					体重：{{petDetail.weight}}
				</el-col>
				<!-- <el-col class="msgItem" :span="6">
					标签：{{petDetail.customFeatures}}
				</el-col> -->
			</el-row>
			<el-row>
				<el-col class="msgItem" :span="6">
					添加人：{{petDetail.operationInfo.create.handleUserName}}
				</el-col>
				<el-col class="msgItem" :span="6">
					宠物状态：{{petDetail.status.name}}
				</el-col>
				<el-col class="msgItem" :span="6">
					宠物备注：{{petDetail.remark}}
				</el-col>
			</el-row>
		</div>
		<!-- <el-radio-group v-model="tabIndex" @change="changeTabIndex" style="padding:20px;">
			<el-radio-button :label="0">洗美记录</el-radio-button>
			<el-radio-button :label="1">寄养记录</el-radio-button>
		</el-radio-group>
		<el-table v-if="tabIndex===0" :data="tableData" style="width: 100%">
			<el-table-column prop="date" label="订单编号">
			</el-table-column>
			<el-table-column prop="name" label="服务日期">
			</el-table-column>
			<el-table-column prop="province" label="洗美项目">
			</el-table-column>
			<el-table-column prop="city" label="活动">
			</el-table-column>
			<el-table-column prop="address" label="价格">
			</el-table-column>
			<el-table-column prop="zip" label="销售人员">
			</el-table-column>
		</el-table>
		<el-table v-if="tabIndex===1" :data="tableData" style="width: 100%">
			<el-table-column prop="date" label="订单编号">
			</el-table-column>
			<el-table-column prop="name" label="服务日期">
			</el-table-column>
			<el-table-column prop="province" label="寄养项目">
			</el-table-column>
			<el-table-column prop="city" label="活动">
			</el-table-column>
			<el-table-column prop="address" label="价格">
			</el-table-column>
			<el-table-column prop="zip" label="销售人员">
			</el-table-column>
		</el-table> -->
		<div v-if="dialogEditPet_state">
			<dialogEditPet :userInfo="userDetail" :curPetItem="petDetail" @submit="submit_dialogEditPet"></dialogEditPet>
		</div>
	</div>
</template>
<script>
	import dialogEditPet from "../../../components/dialogEditPet.vue"
	export default {
		components: {dialogEditPet},
		data() {
			return {
				userDetail:{},
				petDetail:{
					id:'',
					applyType:{},
					categoryInfo:{},
					companyInfo:{},
					status:{},
					operationInfo:{create:{}}
				},
				// 编辑宠物
				dialogEditPet_state:false,
				
				tabIndex:0,
			}
		},
		computed:{
			
			
		},
		mounted() {
			this.getUserDetail();
		},
		methods: {
			changeTabIndex(){
				console.log(this.tabIndex)
			},
			// 编辑宠物信息
			submit_dialogEditPet(obj){
				this.dialogEditPet_state = false;
				if(obj.type==='success'){
					this.getUserDetail();
				}
			},
			// 获取宠物详情
			getUserDetail(){
				let params = {
					id:this.$route.query.userId
				}
				let loading = this.$loading()
				this.$http.get("/customer/Member/Get",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.userDetail = res.data
						for(let i=0;i<this.userDetail.pets_info.length;i++){
							if(this.userDetail.pets_info[i].id===this.$route.query.petId){
								this.petDetail = this.userDetail.pets_info[i]
							}
						}
					}
				})
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		.tit{
			padding:32px 20px 8px;display: flex;justify-content: space-between;
			.titL{
				font-size: 16px;color:$fontColor;background: url(../../../assets/img/cangku/detailName.png) 2px 8px no-repeat;background-size: 24px 24px;
				.titText{
					padding-left:12px;display:inline-block;line-height: 40px;
				}
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.msgBox{
			margin:0 20px;background-color: #FFFCF8;padding:20px 30px;box-sizing: border-box;
			.msgItem{
				font-size: 14px;color:$fontColor;line-height: 40px;
				.sexImg{
					width:14px;height: 14px;vertical-align: middle;
				}
			}
		}
	}
</style>
